import React from 'react';
import { Button } from 'reactstrap';

import CallModal from './CallModal';

export default class MakeCall extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    openModal() {
        this.refs.callModal.toggleModal();
    }

    render() {

        var style = {
            backgroundColor: "#66a73c",
            paddingBottom: "0.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem"
        }

        var btnStyle = {
            height: "5.7rem",
            marginBottom: "0.5rem",
            backgroundColor: "#86bc4f",
            border: "none"
        }

        

        return (
            <div style={style}>
                <CallModal ref="callModal" />
                <Button style={btnStyle} onClick={() => this.openModal()} block={true}>
                    ENTER NUMBER
                </Button>
                <Button style={btnStyle} onClick={() => this.openModal()} block={true}>
                    CALL ON-SITE CONTACT
                </Button>
                <Button style={btnStyle} onClick={() => this.openModal()} block={true}>
                    CALL OFFICE
                </Button>
            </div>
            );
    }
}