import React from 'react';
import { Row, Col, Input } from 'reactstrap';

import { ReactComponent as HangUpIcon } from '../../icons/White-HangUp.svg';
import { ReactComponent as MakeCallIcon } from '../../icons/MakeCall.svg';

export default class CallNumber extends React.Component {

    constructor(props) {
        super(props);

        this.handleNumChange = this.handleNumChange.bind(this);

        this.state = {
            onPhone: false,
            callConnected: false,
            numInput: ''
        }
    }


    componentDidMount() {
    }

    handleCallClick() {
        //Checks for correct number length before passing into the call method
        if (this.state.numInput.length > 5 && this.state.numInput.length < 14) {
            this.props.connectCall(this.state.numInput)
            this.setState({ onPhone: true })
        } else {
            alert("Phone number not valid");
        }
    }

    handleCallConnect() {
        this.setState({ callConnected: true });
    }

    handleHangupClick() {
        this.props.disconnectCall();
        this.setState({ onPhone: false, callConnected: false });
    }

    handleNumChange(event) {
        this.setState({ numInput: event.target.value });
    }


    render() {
        return (
            <div>
                {this.state.onPhone ?
                    <Row noGutters={true}>
                            <Col xs={9} className="call-number-display">
                            <p>{this.state.numInput}</p>
                            {this.state.callConnected ?
                                <small>CONNECTED</small> :
                                "" }
                            </Col>
                        <Col xs={3} onClick={() => this.handleHangupClick()} className="call-action-btn sps-lt-red">
                            <HangUpIcon width={24} height={24} />
                                <br />
                                HANGUP
                            </Col>
                        </Row>
                        :
                        <Row noGutters={true}>
                            <Col xs={9}>
                                <Input onChange={this.handleNumChange} id="phoneNumber" bsSize="lg" type="text" placeholder="01234567891" />
                            </Col>
                            <Col xs={3} onClick={() => this.handleCallClick()} className="call-action-btn">
                                <MakeCallIcon width={28} height={28} />
                                <br />
                                CALL
                            </Col>
                        </Row>
                    }
            </div>
        );
    }
}