import React from 'react';
import { Row, Col } from 'reactstrap';

import { ReactComponent as HangUpIcon } from '../../icons/White-HangUp.svg';

export default class CallPredefined extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            callConnected: false,
        }
    }

    handleCallConnect() {
        this.setState({ callConnected: true });
    }

    handleHangupClick() {
        this.props.disconnectCall();
        this.setState({ callConnected: false });
    }


    render() {
        return (
            <div>
                <Row noGutters={true}>
                    <Col xs={9} className="call-number-display">
                        <p>{this.props.callTo}</p>
                        {this.state.callConnected ?
                            <small>CONNECTED</small> :
                            ""}
                    </Col>
                    <Col xs={3} onClick={() => this.handleHangupClick()} className="call-action-btn sps-lt-red">
                        <HangUpIcon width={24} height={24} />
                        <br />
                            HANGUP
                    </Col>
                </Row>
            </div>
        );
    }
}