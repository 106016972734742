import React from 'react';
import { Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import { handleResponse } from '../../services/response.service';

export class Setup extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            error: null,
            email: null,
            password: null,
        }
    }

    login = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true });

        //Check for no blanks
        if(this.validate()) {
            
            const requestOptions = { 
                method: 'POST', 
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({ "username": this.state.email, "password": this.state.password  }) 
            };

            //Pass in login details and get a token if valid
            fetch('/api/auth/initiallogin', requestOptions)
            .then(handleResponse)
            .then(response => {
                if(response.token) {
                    this.setState({ success: true, isLoading: false});
                } else {
                    this.setState({ error: "Email address and/or password incorrect", isLoading: false});
                }
            })
            .catch(e => {
                this.setState({ error: "Something went wrong! Please contact support", isLoading: false});
            })
        } else {
            this.setState({ error: "Please enter email address and password", isLoading: false});
        }
    }

    handleTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    validate = () => {
        const { email, password } = this.state;
        if(email !== null && email !== "" && password !== null && password !== "") {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <Col 
                xs={12} 
                md={{ size: 10, offset: 1 }} 
                xl={{ size: 6, offset: 3 }}
                style={{ backgroundColor: '#FFF', color: '#000', margin: '4rem auto', padding: '2rem' }}
            >
                <Form onSubmit={(e) => this.login(e)}>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            type="text" 
                            name="email" 
                            id="email" 
                            placeholder="sps118775@sps.engineer"
                            onChange={(e) => this.handleTextChange(e)} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input 
                            type="password"
                            name="password"
                            id="password" 
                            placeholder="Password"
                            onChange={(e) => this.handleTextChange(e)} 
                        />
                    </FormGroup>
                    <Button type="submit">Log in</Button>
                </Form>
            </Col> 
        );
    }
}