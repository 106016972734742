import React from 'react';
import { Row, Col, Modal, ModalBody, Button, Input, Label, FormGroup } from 'reactstrap';

import { ReactComponent as SendReportIcon } from '../../icons/SendReport.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';
import loadingIcon from '../../images/loading.gif';

export default class ReportModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isLoading: false,
            success: false,
            selectedFile: null,
            timestamp: '',
            showUpload: false,
        }

        this.handleFileChange = this.handleFileChange.bind(this);

    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    async sendEmail() {
        if (document.getElementById("msg").value.length > 0) {
            var bodyData = new FormData();
            if (this.state.selectedFile != null) {
                bodyData.append('file', this.state.selectedFile)
                bodyData.append('msg', document.getElementById("msg").value)
            } else {
                bodyData.append('msg', document.getElementById("msg").value)
            }
            this.setState({ isLoading: true });
            // console.log(bodyData);
            var response = await fetch('api/comms/email', {
                method: "POST",
                body: bodyData
            });
            var data = await response.json();
            // console.log(data);
            if (data.value.status === "OK") {
                var mins;
                var d = new Date();
                if (d.getMinutes().toString().length !== 2) {
                    mins = '0' + d.getMinutes();
                } else {
                    mins = d.getMinutes();
                }
                var timestamp = d.getHours() + ':' + mins + ' - ' + d.getDate() + '/' + parseInt(d.getMonth() + 1) + '/' + d.getFullYear();
                this.setState({ isLoading: false, success: true, timestamp: timestamp })
            }
        } else {
            alert("Message body cannot be blank");
        }
    }

    showUpload() {
        this.setState({ showUpload: true });
    }

    handleFileChange(event) {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    handleClose() {
        if (this.state.success === true) {
            this.setState({
                isOpen: false,
                isLoading: false,
                success: false,
                selectedFile: null,
                timestamp: '',
                showUpload: false,
            })
        }
    }

    render() {
        return (
            <Modal onClosed={() => this.handleClose()} isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-dk-srb">
                    <Row>
                        <Col xs="2" className="align-l">
                            <SendReportIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            SEND REPORT
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-lt-srb" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-lt-srb">
                    <div className="custom-modal-content">
                        {this.state.isLoading ?
                            <img src={loadingIcon} alt="Loading...." width="100px" height="100px" />
                            :
                            this.state.success ?
                                <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="msg-modal-content msg-result">
                                    <p>Your message was sent successfully at: </p>
                                    <br />
                                    <h5>{this.state.timestamp}</h5>
                                    <br />
                                    <p>To: </p>
                                    <h5>OFFICE</h5>
                                    <br />
                                    <br />
                                    <h5>Great Job!</h5>
                                </Col>
                                :
                                <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="report-modal-content">
                                    <FormGroup row noGutters={true}>
                                        <Label for="to" xs={2} xl={1}>
                                            To:
                                        </Label>
                                        <Col xs={10} xl={11}>
                                            <Input type="text" id="to" value="OFFICE" disabled />
                                        </Col> 
                                    </FormGroup>
                                    <Input type="textarea" id="msg" />
                                    {this.state.showUpload ? <Input onChange={this.handleFileChange} type="file" className="report-modal-file" /> : ""}
                                    <Row noGutters={true}>
                                        <Col xs={6} xl={3}>
                                            {this.state.showUpload ?
                                                ""
                                                :
                                                <Button onClick={() => this.showUpload()} className="sps-dk-srb">
                                                    UPLOAD
                                                </Button>}
                                        </Col>
                                        <Col xs={{ size: 6 }} xl={{ size: 3, offset: 6 }}>
                                            <Button id="next" onClick={() => this.sendEmail()} className="sps-dk-srb">
                                                SEND
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                        }
                    </div>
                    <Row noGutters={true}>
                        <Col xs="6" xl={2}>
                        </Col>
                        <Col xs="6" xl={{ size: 2, offset: 8 }} className="align-r">
                            <Button onClick={() => this.toggleModal()} className="report-modal-btn">
                                CLOSE
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}