export const datetimeformatterService = {
    formatTime,
    formatTimeNoSeconds,
    formatDate,
    formatDateTime,
    formatDateTimeNoSeconds,
    convertMilliseconds,
}

function formatTime(myDate) {
    const myDateString = ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + ('0' + (myDate.getSeconds())).slice(-2);

    return myDateString;
}

function formatTimeNoSeconds(myDate) {
    const myDateString = ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2);

    return myDateString;
}

function formatDate(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear();

    return myDateString;
}

function formatDateTime(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear() + " " + ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + ('0' + (myDate.getSeconds())).slice(-2);

    return myDateString;
}

function formatDateTimeNoSeconds(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear() + " " + ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2);

    return myDateString;
}

function convertMilliseconds(milliseconds) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: day,
        hour: hour,
        minute: minute,
        seconds: seconds
    };
}