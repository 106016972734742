import React from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import CallNumber from './CallNumber';
import CallPredefined from './CallPredefined';

import { ReactComponent as MakeCallIcon } from '../../icons/MakeCall.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';
import { twilioService } from '../../services/twilio.service';

export default class CallModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            gotToken: false,
            numInputVis: false,
            onSiteVis: false,
            officeVis: false,
        }

        this.connectCall = this.connectCall.bind(this);
        this.disconnectCall = this.disconnectCall.bind(this);
    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    connectCall(num) {
        //var formattedNum = '+44' + num.substr(1);
        //twilioService.device.connect({ "To": formattedNum, "From": localStorage.getItem("TwilioIdentity") });
        const url = 'http://demo.twilio.com/docs/voice.xml';
        twilioService.device.connect({ "To": num, "From": "+18187405474", "twiml": url });
        
    }

    disconnectCall() {
        this.setState({ numInputVis: false, onSiteVis: false, officeVis: false });
        twilioService.device.disconnectAll();
    }

    showNumInput = () => {
        this.setState({ numInputVis: true, onSiteVis: false, officeVis: false });
    }

    async callOnSiteContact() {
        this.setState({ numInputVis: false, officeVis: false })
        if (!this.state.onSiteVis) {
            //TO DO: Convert this to pull numbers from main API
            var response = await fetch('api/comms/getcallnos');
            var data = await response.json();
            // console.log(data);
            this.connectCall(data.onsiteNumber);
            this.setState({ onSiteVis: true });
        }
    }

    async callOffice() {
        this.setState({ numInputVis: false, onSiteVis: false })
        if (!this.state.officeVis) {
            //TO DO: Convert this to pull numbers from main API
            var response = await fetch('api/comms/getcallnos');
            var data = await response.json();
            // console.log(data);
            this.connectCall(data.officeNumber);
            this.setState({ officeVis: true });
        }
    }

    resolveOpenCallConn() {
        if (this.state.numInputVis) {
            this.refs.callNumber.handleCallConnect();
        } else if (this.state.onSiteVis) {
            this.refs.callOnsite.handleCallConnect();
        } else if (this.state.officeVis) {
            this.refs.callOffice.handleCallConnect();
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-dk-grn">
                    <Row>
                        <Col xs="2" className="align-l">
                            <MakeCallIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            MAKE A CALL
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-lt-grn" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-lt-grn">
                    <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}  className="custom-modal-content">
                        <div onClick={() => this.showNumInput()} className="custom-modal-btn sps-dk-grn">
                            {this.state.numInputVis ?
                                <CallNumber
                                    ref="callNumber"
                                    connectCall={this.connectCall}
                                    disconnectCall={this.disconnectCall}
                                /> :
                                "ENTER NUMBER" }
                        </div>
                        <div onClick={() => this.callOnSiteContact()} className="custom-modal-btn sps-dk-grn">
                            {this.state.onSiteVis ?
                                <CallPredefined
                                    ref="callOnsite"
                                    disconnectCall={this.disconnectCall}
                                    callTo="ON-SITE CONTACT"
                                /> :
                                "ON-SITE CONTACT"}
                        </div>
                        <div onClick={() => this.callOffice()} className="custom-modal-btn sps-dk-grn">
                            {this.state.officeVis ?
                                <CallPredefined
                                    ref="callOffice"
                                    disconnectCall={this.disconnectCall}
                                    callTo="OFFICE"
                                /> :
                                "OFFICE"}
                        </div>
                    </Col>
                    <Row noGutters={true}>
                        <Col xs="6" xl={2}>
                        </Col>
                        <Col xs="6" xl={{ size: 2, offset: 8 }} className="align-r">
                            <Button onClick={() => this.toggleModal()} className="call-modal-btn">
                                CLOSE
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}