import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';


export default class ButtonTile extends React.Component {

    render() {

        var size;
        this.props.halfSize ? size = "half" :  size = "full";

        var style = {
            backgroundColor: `${this.props.bgColour}`
        }

        return (
            <Card onClick={this.props.onClick} style={style} className={`button-tile-${size} button-tile`}>
                <div className="button-tile-bg">
                    {this.props.bgImage}
                </div>
                <div className="button-tile-text">
                    {this.props.text}
                </div>
            </Card>
        );
    }
}


ButtonTile.propTypes = {
    bgColour: PropTypes.string,
    bgImage: PropTypes.object,
    text: PropTypes.string
}