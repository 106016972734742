import React, { Component } from 'react';
import { Route } from 'react-router';
import { Col, Spinner } from 'reactstrap';
import { Layout } from './components/Layout';
import { Dashboard } from './components/Dashboard/Dashboard';
import { Setup } from './components/Setup/Setup';
import { authService } from './services/auth.service';
import { twilioService, offlineClientTimout } from './services/twilio.service';


export default class App extends Component {
  
  constructor() {
    super() 

    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
    //Ask internal API for token, don't load anything untill we have token 
    var fullPath = window.location.pathname;
    fullPath.indexOf(1)
    var firstElem = fullPath.split("/")[1];
    var firstElemLower = firstElem.toLowerCase();
    if(firstElemLower !== "inital-setup") {
      authService.authHeader();
      authService.currentUserSubject.subscribe(user => {
        if(typeof user !== "undefined") {
          this.setState({ isLoading: false })
        }
      })
    } else { 
      this.setState({ isLoading: false })
    }

    //Listen for visibility events, tell API twilio client has disconnected
    document.addEventListener("visibilitychange", () => {
      // console.log("vis changed");
      if(document.visibilityState === 'visible') {
        if(localStorage.getItem("TwilioIdentity") !== null) {
          offlineClientTimout.stopTimer();
        } else {
          twilioService.setupTwilio();
        }
      } else if(document.visibilityState === 'hidden'){
        // console.log("AAAHHH DOC IS HIDDEN");
        offlineClientTimout.startTimer();
        //twilioService.registerOffline();
      } else {
        offlineClientTimout.startTimer();
      }
    });
  }

  render () {


    return (
      <Layout>
        { 
          this.state.isLoading ?
            //Loading
            <Col 
              xs={12} 
              md={{ size: 10, offset: 1 }} 
              xl={{ size: 6, offset: 3 }}
              style={{ textAlign: 'center' }}
            >
              <Spinner style={{ marginTop: '15rem', width: '10rem', height: '10rem'  }} />
            </Col>
            :
            <React.Fragment>
              <Route exact path='/' component={Dashboard} />
              <Route exact path='/inital-setup' component={Setup} />
            </React.Fragment>
        }
      </Layout>
    );
  }
}
