import React from 'react';
import { Container } from 'reactstrap';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { ReactComponent as WifiIcon } from '../../icons/WiFi.svg';
import { ReactComponent as ReadingsIcon } from '../../icons/Alerts.svg';
import { ReactComponent as MakeCallIcon } from '../../icons/MakeCall.svg';
import { ReactComponent as SendMsgIcon } from '../../icons/SendMessage.svg';
import { ReactComponent as SendReportIcon } from '../../icons/SendReport.svg';
import { ReactComponent as RaiseAlarmIcon } from '../../icons/RaiseAlarm.svg';
import { ReactComponent as PartyModeIcon } from '../../icons/PartyModeBG.svg';
import { ReactComponent as FactOfTheDayIcon } from '../../icons/FactOfTheDay.svg';

import DetailTile from './DetailTile';
import ButtonTile from './ButtonTile';
import MakeCall from './MakeCall';
import SendMessage from './SendMessage';
import WifiDetails from './WifiDetails';
import RecentReadings from './RecentReadings';
import PartyModeModal from './PartyModeModal';
import FactModal from './FactModal';
import ReportModal from './ReportModal';
import EmergencyModal from './EmergencyModal';

import { twilioService } from '../../services/twilio.service';

import './Dashboard.scss';

export class Dashboard extends React.Component {

    handleOpenModal = (modal) => {
        this.refs[modal].toggleModal();
    }

    componentDidMount() {
        twilioService.setupTwilio();
    }

    render() {
        return (
            <Container className="dash-root" fluid={true}>
                <PartyModeModal ref="partyModeModal" />
                <FactModal ref="factModal" />
                <ReportModal ref="reportModal" />
                <EmergencyModal ref="emergencyModal" />
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 600: 2, 1024: 4 }}
                >
                    <Masonry className="flex-grid">
                        <DetailTile
                            colour="darkblue"
                            title="WIFI"
                            icon={<WifiIcon width={32} height={32} />}
                        >
                            <WifiDetails />
                        </DetailTile>
                        <DetailTile
                            colour="lightblue"
                            title="RECENT READINGS"
                            icon={<ReadingsIcon width={32} height={32} />}
                            open={true}
                        >
                            <RecentReadings />
                        </DetailTile>
                        <DetailTile
                            colour="green"
                            title="MAKE A CALL"
                            icon={<MakeCallIcon width={32} height={32} />} 
                        >
                            <MakeCall />
                        </DetailTile>
                        <DetailTile
                            colour="orange"
                            title="SEND SMS"
                            icon={<SendMsgIcon width={32} height={32} />}
                        >
                            <SendMessage />
                        </DetailTile>
                        <ButtonTile
                            halfSize={false}
                            text="SEND REPORT"
                            bgColour="#55a4ce"
                            bgImage={<SendReportIcon width={180} height={180} />}
                            onClick={() => this.handleOpenModal("reportModal")}
                        />
                        <ButtonTile
                            halfSize={false}
                            text="EMERGENCY"
                            bgColour="#CC0000"
                            bgImage={<RaiseAlarmIcon width={170} height={170} />}
                            onClick={() => this.handleOpenModal("emergencyModal")}
                        />
                        <ButtonTile
                            halfSize={true}
                            text="PARTY MODE"
                            bgColour="#504b8d"
                            bgImage={<PartyModeIcon width={200} />}
                            onClick={() => this.handleOpenModal("partyModeModal")}
                        />
                        <ButtonTile
                            halfSize={true}
                            text="TELL ME A SAFETY OR GAS FACT"
                            bgColour="#707070"
                            bgImage={<FactOfTheDayIcon width={80} height={80} />}
                            onClick={() => this.handleOpenModal("factModal")}
                        />
                    </Masonry>
                </ResponsiveMasonry>
            </Container>
        );
    }
}