export const processService = {
    ProcessReading,
}

//Define properties for each reading, from each type of sensor
const rules = [
    { uglyType: "percentage", prettyType: "HUMIDITY", unit: "%", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "temperaturedata", prettyType: "TEMPERATURE", unit: "°C", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "temperature", prettyType: "L8", unit: "°C", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "luxdata", prettyType: "LIGHT", unit: "LUX", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "motion", prettyType: "PIR", unit: "", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "ppm", prettyType: "CO LEVEL", unit: "ppm", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "db", prettyType: "SOUND", unit: "dB", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "airquality", prettyType: "AIR QUALITY", unit: "µg/m³", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "centimeter", prettyType: "WATER LEVEL", unit: "cm", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "waterdetect", prettyType: "WATER ROPE", unit: "", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    // { uglyType: "twa", prettyType: "CO TWA LEVEL", unit: "", value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null }

]



function ProcessReading(readings) {
    var processedData = [];
    if (readings !== undefined) {

        readings.forEach((reading) => {
            if (reading.value !== null) {
                var types = [];
                if (reading.type === "Centimeter|Centimeter") {
                    //Water level needs to split only the type, only has one reading
                    const splits = reading.type.split('|');
                    types[0] = splits[0];
                } else if (reading.type === "PPM|PPM|TemperatureData") {
                    //Need to split this into two, not three
                    const splits = reading.type.split('|');
                    types[0] = splits[0];
                } else {
                    types = reading.type.split('|');
                }

                var values = [];
                if (reading.type === "Centimeter") {
                    const splits = reading.value.split('|');
                    values[0] = splits[0];
                } else if (reading.type === "airquality") {
                    values[0] = reading.value;
                } else if (reading.type === "PPM|PPM|TemperatureData") {
                    const splits = reading.value.split('|');
                    values[0] = splits[0] + "|" + splits[1];
                } else {
                    values = reading.value.split('|');
                }

                types = types.map(v => v.toLowerCase());

                // console.log("After splits");
                // console.log(values, types);



                if (types[1] === "temperaturedata" && types[2] === "temperaturedata") {
                    types[2] = "not used";
                }

                types.forEach((type, i) => {
                    // Checks to see if there a a matching sensor in our list and map a reading to singleSplitReading
                    let singleSplitReading = rules.find((sensor) => (sensor.uglyType === type));

                    //Checks that the singleSplitReading has a type that's not undefined
                    if (typeof singleSplitReading !== "undefined" && values[i] !== null) {

                        //Attaches values to the reading, sets a colour depending on alert and pushes to an array
                        singleSplitReading = { type: singleSplitReading.prettyType, value: values[i], unit: singleSplitReading.unit, alertLevel: reading.alertLevel, readingTime: new Date(reading.readingTime), sensorId: reading.sensorID, batteryLevel: reading.batteryLevel, signalStrength: reading.signalStrength };

                        singleSplitReading = SetAlertColours(singleSplitReading);

                        processedData.push(singleSplitReading);
                    }
                })
            }
        })
    }

    return processedData;

}

function SetAlertColours(reading) {
    switch (reading.alertLevel) {
        case (0):
            reading.colour = "green";
            break;
        case (1):
            reading.colour = "amber";
            break;
        case (2 || 3):
            reading.colour = "red";
            break;
        default:
            reading.colour = undefined;
            break;
    }
    return reading;
}
