import React from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';

import { ReactComponent as RaiseAlarmIcon } from '../../icons/RaiseAlarm.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';
import  loadingIcon  from '../../images/loading.gif';

export default class EmergencyModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isCancelable: true,
            showUpdates: false,
            confirmFor: '',
            updates: []
        }

    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    getUpdates = async () => {
        //Gets any sms in the queue
        var response = await fetch('api/comms/getsmsqueue');
        //If resonse is good then send all of the messages to the updateWindow method
        if (response.status === 200) {
            var data = await response.json();
            // console.log(data);
            data.forEach((msg) => {
                this.addToUpdateWindow(msg.body);
            })
        } else {
            // console.log("No updates");
        }
    }

    addToUpdateWindow = (msg) => {
        //Get the date now
        var d = new Date();

        //If minutes is between 0 and 9 then add a zero to the front
        var mins;
        if (d.getMinutes().toString().length !== 2) {
            mins = '0' + d.getMinutes();
        } else {
            mins = d.getMinutes();
        }

        //format timestamp
        var timestamp = d.getHours() + ':' + mins + ' - ' + d.getDate() + '/' + parseInt(d.getMonth() + 1) + '/' + d.getFullYear();

        //Create object to push
        var newUpdate = {
            message: msg,
            timestamp: timestamp
        };

        var currUpdates = this.state.updates.reverse();
        currUpdates.push(newUpdate);

        this.setState({ updates: currUpdates.reverse() });
    }

    async sendEmergencyMsg() {
        this.setState({ isLoading: true, isCancelable: false });
        var response = await fetch('api/comms/emergencyservices')
        if (response.status === 200) {
            window.setInterval(this.getUpdates, 3000);
            this.setState({ isLoading: false, showUpdates: true, confirmFor: '' });
        } else {
            this.setState({ error: true });
        }
    }

    confirmationShow(confirm) {
        //change output depending on which button user clicked
        switch (confirm) {
            case "999":
                return (
                    <div className="emergency-warning">
                        CONTACT '999' EMERGENCY SERVICES
                        <div onClick={() => this.sendEmergencyMsg()} className="emergency-modal-action-btn sps-lt-red">
                            CONFIRM
                        </div>
                    </div>
                );
            case "designated":
                return (
                    <div className="emergency-warning">
                        CONTACT DESIGNATED EMERGENCY CONTACTS
                        <div className="emergency-modal-action-btn sps-lt-red">
                            CONFIRM
                        </div>
                    </div>
                );
            case "alarm":
                return (
                    <div className="emergency-warning">
                        RAISE ALL ALARMS
                        <div className="emergency-modal-action-btn sps-lt-red">
                            CONFIRM
                        </div>
                    </div>
                );
            default:
                return (
                    <div>
                        <div onClick={() => this.setState({ confirmFor: "alarm" })} className="emergency-modal-action-btn sps-lt-red">
                            RAISE ALL ALARMS
                        </div>
                        <div onClick={() => this.setState({ confirmFor: "designated" })} className="emergency-modal-action-btn sps-lt-red">
                            CONTACT DESIGNATED EMERGENCY CONTACTS
                        </div>
                        <div onClick={() => this.setState({ confirmFor: "999" })} className="emergency-modal-action-btn sps-lt-red">
                            CONTACT '999' EMERGENCY SERVICES
                        </div>
                    </div>
                );
        }
    }

    clearState() {
        this.setState({
            isOpen: false,
            isCancelable: true,
            showUpdates: false,
            confirmFor: '',
            updates: []
        });
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-lt-red">
                    <Row>
                        <Col xs="2" className="align-l">
                            <RaiseAlarmIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            EMERGENCY
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-dk-red" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-dk-red">
                    {this.state.isLoading ?
                        <div className="custom-modal-content">
                            <img src={loadingIcon} alt="Loading...." width="100px" height="100px" />
                        </div>
                        :
                        <div>
                            <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="emergency-modal-content">
                                {this.confirmationShow(this.state.confirmFor)}
                            </Col>
                            {this.state.showUpdates ?
                                <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="emergency-updates">
                                    <strong>Updates</strong>
                                    {this.state.updates.map((update, index) => {
                                        return (
                                            <div key={index}>
                                                {update.timestamp}
                                                <br />
                                                {update.message}
                                                <br />
                                                <br />
                                            </div>
                                        );
                                    })}
                                </Col>
                                :
                                <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="emergency-warning">
                                    EMERGENCY USE ONLY
                                </Col>
                            }
                            <Row noGutters={true}>
                                {this.state.isCancelable ?
                                    <Col xs={6} xl={2}>
                                        <Button onClick={() => this.clearState()} className="emergency-modal-btn">
                                            CANCEL
                                        </Button>
                                    </Col>
                                    :
                                    <Col xs={6} xl={2}>
                                    </Col>
                                }
                                <Col xs="6" xl={{ size: 2, offset: 8 }} className="align-r">
                                    <Button onClick={() => this.toggleModal()} className="emergency-modal-btn">
                                        CLOSE
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
            </Modal>
        );
    }
}