import React from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';

import { ReactComponent as PartyModeIcon } from '../../icons/White-PartyMode.svg';
import { ReactComponent as PartyModeBG } from '../../icons/PartyModeBG.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';

const a = new Audio('partymode.mp3');

export default class PartyModeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isPartyTime: false,
        }

    }

    handleParty() {
        this.setState({ isPartyTime: true })
        a.play();
    }

    stopParty() {
        a.pause();
        a.currentTime = 0;
        this.setState({ isPartyTime: false });
    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-lt-pmb">
                    <Row>
                        <Col xs="2" className="align-l">
                            <PartyModeIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            PARTY MODE
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-dk-pmb" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-dk-pmb">
                    <div className="custom-modal-content">
                        {this.state.isPartyTime ?
                            <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="party-modal-content">
                                Playing Insomnia by Faithless
                                <Row noGutters={true}>
                                    <Col xs={6} xl={3}>
                                        <Button onClick={() => this.toggleModal()} className="sps-lt-pmb">
                                            BACK
                                        </Button>
                                    </Col>
                                    <Col xs={{ size: 6 }} xl={{ size: 3, offset: 6 }}>
                                        <Button onClick={() => this.stopParty()} className="sps-lt-pmb">
                                            STOP
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            :
                            <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="party-modal-content">
                                <div class="party-modal-bg-wrap">
                                    <PartyModeBG class="party-modal-bg" />
                                    <h5>WOULD YOU CARE TO</h5><h4>PARTY?</h4>
                                </div>
                                <div onClick={() => this.handleParty()} className="custom-modal-btn sps-lt-pmb">
                                    HELL YEAH!
                                </div>
                                <div onClick={() => this.toggleModal()} className="custom-modal-btn sps-lt-pmb">
                                        ERR.... NO
                                </div>
                            </Col>}
                    </div>
                    <Row noGutters={true}>
                        <Col xs={6} xl={2}>
                        </Col>
                        <Col xs={6} xl={{ size: 2, offset: 8 }} className="align-r">
                            <Button onClick={() => this.toggleModal()} className="party-modal-btn">
                                CLOSE
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}