import { BehaviorSubject } from 'rxjs';
import { handleResponse } from './response.service';
import jwt_decode from "jwt-decode";

const currentUserSubject = new BehaviorSubject();

export const authService = {
    authHeader,
    currentUserSubject, 
    get currentSite () { return typeof currentUserSubject.value !== "undefined" ? currentUserSubject.value.SiteId0 : 0 },
    // get boxName () { return currentUserSubject.value.boxname }
    get boxName () { return "boxname" }
}

async function authHeader() {
    return new Promise(async (resolve, reject) => {
        await fetch('/api/auth/gettoken')
        .then(handleResponse)
        .then(tokenData => {
            if(tokenData.status !== "FAIL") {
                var tokenInfo = jwt_decode(tokenData.token);
                tokenInfo.token = tokenData.token;
                currentUserSubject.next(tokenInfo);
                resolve({ Authorization: `Bearer ` + tokenData.token });
            } else {
                reject({ Authorization: `Bearer` });
            }
        })
        .catch(e => {
            reject({ Authorization: `Bearer` });
        })
    })
}