import React from 'react';

import { apiService } from '../../services/api.service';

import { ReactComponent as WifiNavy } from '../../icons/Navy-WiFi.svg';
import loadingIcon from '../../images/loading.gif';
import { authService } from '../../services/auth.service';

export default class WifiDetails extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            ssid: '',
            password: '',
            error: null
        }
    }

    componentDidMount() {
        this.getWifiDetials();
    }

    getWifiDetials = async () => {
        //API call for the wifi details for that site
        await apiService.getData("/engineer/wifi/" + authService.currentSite)
        .then(wifiDetails => {
            this.setState({ isLoading: false, ssid: wifiDetails.wifiSsid, password: wifiDetails.wifiPass })
        })
        .catch(e => {
            this.setState({ error: true, isLoading: false })
        })
    }

    render() {

        const loadingStyle = {
            textAlign: "center",
            paddingTop: "2rem",
        }

        return (
            this.state.isLoading ?
                <div className="wifi-tile" style={loadingStyle}>
                    <img src={loadingIcon} alt="Loading...." width="100px" height="100px" />
                </div>
                    :
                this.state.error ?
                    <div className="wifi-tile" style={loadingStyle}>
                        Error loading data
                    </div>
                        :
                    <div className="wifi-tile">
                        <div className="wifi-tile-bg">
                            <WifiNavy width={160} height={160} />
                        </div>
                        <div className="wifi-tile-text">
                            SSID: <br />
                            <strong>{this.state.ssid}</strong>
                        </div>
                        <div className="wifi-tile-text">
                            PASSWORD: <br />
                            <strong>{this.state.password}</strong>
                        </div>
                    </div>
        );
    }
}