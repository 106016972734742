import { Device } from 'twilio-client';
import { apiService } from './api.service';
import { authService } from './auth.service';

const device = new Device();

const setupTwilio = async () => {

    let isBox = false;

    //If we are the box itself, tell the API that
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        isBox = true;
    }

    //Get the Twilio token from main API, save the Identity and setup the twilio client 
    apiService.postData('/twilio/clienttoken', { isBox: isBox, boxId: authService.boxName })
        .then(twilioToken => {
            // console.log("TWILIO TOKEN");
            // console.log(twilioToken);
            localStorage.setItem("TwilioIdentity", twilioToken.identity);
            device.setup(twilioToken.token, {
                appName: twilioToken.identity,
                codecPreferences: ["opus", "pcmu"],
                fakeLocalDTMF: true,
                enableRingingState: true
            });
        })

    device.on('ready', () => {
        // console.log("Twilio ready");
        twilioHeartbeat.startTimer();
    }) 

    device.on('offline', (con) => {
        // console.log("Twilio offline")
        if(localStorage.getItem("TwilioIdentity") !== null) {
            apiService.postData('/twilio/clientoffline', { identity: localStorage.getItem("TwilioIdentity") });
            localStorage.removeItem("TwilioIdentity");
        }
        if(twilioHeartbeat.isRunning()) {
            twilioHeartbeat.stopTimer();
        }
    })
}

const registerOffline = () => {
    // console.log("Reg offline");
    device.destroy();
    twilioHeartbeat.stopTimer();
    offlineClientTimout.stopTimer();
}

class twilioHeartbeat {

    static timer = false;

    static sendHeartbeat = () => {
        // console.log("Sending twilio heartbeat...")
        apiService.postData('/twilio/clientheartbeat', { identity: localStorage.getItem("TwilioIdentity") });
    }

    static startTimer = () => {
        if(!this.isRunning()) {
            //5 mins in ms = 300000 // 2 mins = 120000
            // console.log("Starting twilio heartbeat...");
            this.timer = setInterval(this.sendHeartbeat, 120000);
        }
    }

    static stopTimer = () => {
        // console.log("Stopping twilio heartbeat....")
        clearInterval(this.timer);
        this.timer = false;
    }

    static isRunning = () => {
        return this.timer !== false;
    }
}

export class offlineClientTimout {

    static timer = false;

    static startTimer = () => {
        if(!this.isRunning()) {
            //5 mins in ms = 300000 // 2 mins = 120000
            // console.log("Starting twilio disconnect timer...");
            this.timer = setInterval(registerOffline, 120000);
        }
    }

    static stopTimer = () => {
        // console.log("Stopping twilio disconnect timer...");
        clearInterval(this.timer);
        this.timer = false;
    }

    static isRunning = () => {
        return this.timer !== false;
    }
}

export const twilioService = {
    device,
    setupTwilio,
    registerOffline
}