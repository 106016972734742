import React from 'react';
import { Button } from 'reactstrap';

import MessageModal from './MessageModal';

export default class SendMessage extends React.Component {

    openModal(to) {
        this.refs.messageModal.toggleModal(to);
    }

    render() {

        var style = {
            backgroundColor: "#f39b32",
            paddingBottom: "0.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem"
        }

        var btnStyle = {
            height: "5.7rem",
            marginBottom: "0.5rem",
            backgroundColor: "#ed7631",
            border: "none"
        }

        return (
            <div style={style}>
                <MessageModal ref="messageModal" />
                <Button onClick={() => this.openModal("office")} style={btnStyle} block={true}>
                    TO OFFICE
                </Button>
                <Button onClick={() => this.openModal("onsite")} style={btnStyle} block={true}>
                    TO ON-SITE CONTACT
                </Button>
                <Button onClick={() => this.openModal()}  style={btnStyle} block={true}>
                    TO ANYONE
                </Button>
            </div>
        );
    }
}