import React from 'react';
import { Row, Col, Modal, ModalBody, Popover, PopoverHeader, PopoverBody, Button } from 'reactstrap';

import { ReactComponent as ReadingsIcon } from '../../icons/Alerts.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';
import { ReactComponent as GreenExpArrowOff } from '../../icons/Green-OffState-RecentReading.svg';
import { ReactComponent as GreenExpArrowOn } from '../../icons/Green-Rollover-RecentReading.svg';
import { ReactComponent as AmberExpArrowOff } from '../../icons/Amber-OffState-RecentReading.svg';
import { ReactComponent as AmberExpArrowOn } from '../../icons/Amber-Rollover-RecentReading.svg';
import { ReactComponent as RedExpArrowOff } from '../../icons/Red-OffState-RecentReading.svg';
import { ReactComponent as RedExpArrowOn } from '../../icons/Red-Rollover-RecentReading.svg';
import { ReactComponent as GreyExpArrowOff } from '../../icons/Grey-OffState-RecentReading.svg';
import { ReactComponent as GreyExpArrowOn } from '../../icons/Grey-Rollover-RecentReading.svg';
import { ReactComponent as CalendarIcon } from '../../icons/White-DateorCalendar.svg';

import loadingIcon from '../../images/loading.gif';
import { datetimeformatterService } from '../../services/datetimeformatter.service';

export default class ReadingsModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redisHover: false,
            redActive: false,
            amberisHover: false,
            amberActive: false,
            greenisHover: false,
            greenActive: false,
            greyisHover: false,
            greyActive: false,
            showDatePicker: false,
            isOpen: false,
            isLoading: false,
            startdateValue: '',
            enddateValue: '',
            fullReadings: props.readings,
            readings: props.readings
        }
    }

    toggleModal(filter) {
        if (filter !== "grey") {
            this.applyFilter(filter);
            this.setState({ isOpen: !this.state.isOpen, [filter + 'Active']: true });
        } else {
            this.setState({ isOpen: !this.state.isOpen, [filter + 'Active']: true });
        }
    }

    toggleHover(elem) {
        this.setState({ [elem + 'isHover']: !this.state[elem + 'isHover'] })
    }

    applyFilter(filter) {
        var readings = [];

        //Switch on filter colour and return readings based on alert levels
        switch (filter) {
            case "red":
                this.state.fullReadings.forEach((reading) => {
                    if (reading.alertLevel === 3 || reading.alertLevel === 2) {
                        readings.push(reading);
                    }
                });
                this.setState({ readings: readings });
                break; 
            case "amber":
                this.state.fullReadings.forEach((reading) => {
                    if (reading.alertLevel === 1) {
                        readings.push(reading);
                    }
                });
                this.setState({ readings: readings });
                break;
            case "green":
                this.state.fullReadings.forEach((reading) => {
                    if (reading.alertLevel === 0) {
                        readings.push(reading);
                    }
                });
                this.setState({ readings: readings });
                break;
            default:
                this.setState({ readings: this.state.fullReadings });
                break;
        }

        this.setState({
            greenActive: false,
            amberActive: false,
            redActive: false,
            greyActive: false,
            [filter + 'Active']: true
        });
    }

    toggleShowDatePicker() {
        this.setState({ showDatePicker: !this.state.showDatePicker })
    }

    handleDateUpdate(event, elem) {
        this.setState({ [elem + 'dateValue']: event.target.value });
    }

    getReadings = async () => {
        //Check for both dates
        if (this.state.startdateValue !== '' && this.state.enddateValue !== '') {
            this.setState({ isLoading: true });

            //Get readings for that range from the database and parse it
            var response = await fetch('api/readings/recent?startdate=' + this.state.startdateValue + '&enddate=' + this.state.enddateValue, { cache: "no-store" });
            var data = await response.json();
            // console.log(data);

            //Add readings to state
            this.setState({ readings: data, fullReadings: data, isLoading: false });
        } else {
            alert("Please enter both dates!");
        }
    }

    resetState() {
        this.setState({
            redisHover: false,
            redActive: false,
            amberisHover: false,
            amberActive: false,
            greenisHover: false,
            greenActive: false,
            greyisHover: false,
            greyActive: false,
            showDatePicker: false,
        })
    }

    render() {
        return (
            <Modal onClosed={() => this.resetState()} isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-dk-rrb">
                    <Row>
                        <Col xs="2" className="align-l">
                            <ReadingsIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            RECENT READINGS
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-lt-rrb" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-lt-rrb reading-modal-body">
                    <Row noGutters className="reading-modal-icons">
                        <Col xs={{ size: 2, offset: 1 }} >
                            <div onClick={() => this.applyFilter("red")} onMouseEnter={() => this.toggleHover("red")} onMouseLeave={() => this.toggleHover("red")}>
                                {this.state.redisHover ?
                                    <RedExpArrowOn width={65} height={65} /> :
                                    <RedExpArrowOff width={65} height={65} />}
                                {this.state.redActive ?
                                    <div className="active-indicator-on"></div>:
                                    <div className="active-indicator"></div>}
                            </div>
                        </Col>
                        <Col xs={{ size: 2 }} >
                            <div onClick={() => this.applyFilter("amber")} onMouseEnter={() => this.toggleHover("amber")} onMouseLeave={() => this.toggleHover("amber")}>
                                {this.state.amberisHover ?
                                    <AmberExpArrowOn width={65} height={65} /> :
                                    <AmberExpArrowOff width={65} height={65} />}
                                {this.state.amberActive ?
                                    <div className="active-indicator-on"></div> :
                                    <div className="active-indicator"></div>}
                            </div>
                        </Col>
                        <Col xs={{ size: 2 }} >
                            <div onClick={() => this.applyFilter("green")} onMouseEnter={() => this.toggleHover("green")} onMouseLeave={() => this.toggleHover("green")}>
                                {this.state.greenisHover ?
                                    <GreenExpArrowOn width={65} height={65} /> :
                                    <GreenExpArrowOff width={65} height={65} />}
                                {this.state.greenActive ?
                                    <div className="active-indicator-on"></div> :
                                    <div className="active-indicator"></div>}
                            </div>
                        </Col>
                        <Col xs={{ size: 2 }} >
                            <div onClick={() => this.applyFilter("grey")} onMouseEnter={() => this.toggleHover("grey")} onMouseLeave={() => this.toggleHover("grey")}>
                                {this.state.greyisHover ?
                                    <GreyExpArrowOn width={65} height={65} /> :
                                    <GreyExpArrowOff width={65} height={65} />}
                                {this.state.greyActive ?
                                    <div className="active-indicator-on"></div> :
                                    <div className="active-indicator"></div>}
                            </div>
                        </Col>
                        <Col xs={{ size: 2 }} >
                            <CalendarIcon onClick={() => this.toggleShowDatePicker()} id="openPop" className="reading-modal-cal" width={60} height={60} />
                            <Popover placement="bottom" isOpen={this.state.showDatePicker} target="openPop" toggle={() => this.toggleShowDatePicker()}>
                                <PopoverHeader>Date Range</PopoverHeader>
                                <PopoverBody>
                                    Start Date: <input type="date" onChange={(e) => this.handleDateUpdate(e, "start")} className="form-control" />
                                    <br />
                                    End Date: <input type="date" onChange={(e) => this.handleDateUpdate(e, "end")} className="form-control" />
                                    <br />
                                    <Button onClick={() => this.getReadings()} className="btn sps-dk-rrb btn-block">
                                        Get Readings
                                    </Button>
                                </PopoverBody>
                            </Popover>
                        </Col>
                    </Row>
                    <Row noGutters className="sps-dk-rrb reading-table-head">
                        <Col className="status-lbl" xs={1}>Status</Col>
                        <Col xs={2}>Date</Col>
                        <Col xs={2}>Time</Col>
                        <Col xs={3}>Sensor</Col>
                        <Col xs={3}>Reading</Col>
                    </Row>
                    <div className="reading-modal-content">
                    {this.state.isLoading ?
                        <Col xs={10}>
                            <img src={loadingIcon} className="rr-loading" alt="Loading...." width="100px" height="100px" />
                        </Col>
                        :
                        this.state.readings.map((reading) => {
                            var colourClass;
                            if (reading.alertLevel === 0) {
                                colourClass = "green";
                            } else if (reading.alertLevel === 1) {
                                colourClass = "orange";
                            } else {
                                colourClass = "red";
                            }
                            return (
                                <Row className="rr-reading" noGutters={true}>
                                    <Col xs={1}>
                                        <div className={`rr-reading-colour rr-icon-bg-${colourClass}`}>
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        {datetimeformatterService.formatDate(reading.readingTime)}
                                    </Col>
                                    <Col xs={2}>
                                        {datetimeformatterService.formatTime(reading.readingTime)}
                                    </Col>
                                    <Col xs={3}>
                                        {reading.type}
                                    </Col>
                                    <Col xs={3}>
                                        {reading.value}{reading.unit}
                                    </Col>
                                </Row>
                            );
                        })}
                        </div>
                </ModalBody>
            </Modal>
        );
    }
}