import React from 'react';
import { Row, Col, Modal, ModalBody, Input, FormGroup, Label, Button } from 'reactstrap';

import { ReactComponent as SendMsgIcon } from '../../icons/SendMessage.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';
import loadingIcon from '../../images/loading.gif';

export default class MessageModal extends React.Component {

    constructor(props) {
        super(props);

        this.handleNumChange = this.handleNumChange.bind(this);

        this.state = {
            isOpen: false,
            isLoading: false,
            predefinedTo: false,
            success: false,
            numInput: '',
            timestamp: '',
        }

    }

    resetFields() {
        this.setState({
            isOpen: false,
            isLoading: false,
            predefinedTo: false,
            success: false,
            numInput: '',
            timestamp: '',
        });
    }

    toggleModal(to) {
        if (to === null || to === '' || to===undefined) {
            this.setState({ isOpen: !this.state.isOpen });
        } else {
            if (to === "office") {
                this.setState({ isOpen: !this.state.isOpen, predefinedTo: true, displayName: "OFFICE" });
            } else if(to === "onsite") {
                this.setState({ isOpen: !this.state.isOpen, predefinedTo: true, displayName: "ON-SITE CONTACT" });
            }
        }
    }

    handleNumChange(event) {
        var num = '+44' + event.target.value.substr(1);
        this.setState({ numInput: num });
    }

    async sendMsg() {
        var num;
        var response;
        var data;
        var mins;
        // var apiUrl = "/";
        if (!this.state.predefinedTo) {
            //Number is not predef, must be entered
            if (this.state.numInput.length > 8 && this.state.numInput.length < 14) {
                //Number entered is of correct len
               num = this.state.numInput;
            } else {
                alert("Phone number is invalid");
            }
        } else {
            //Number is predef, get related number from db
            if (this.state.displayName === "OFFICE") {
                 var response = await fetch('api/comms/getsmsnos');
                 var data = await response.json();
                 console.log(data);
                 var num = data.officeNumber;
            } else if (this.state.displayName === "ON-SITE CONTACT") {
                response = await fetch('api/comms/getsmsnos');
                data = await response.json();
                num = data.onsiteNumber;
            }
        }
        //We have worked out where the text is destin for, check the body has content, send the msg
        if (document.getElementById("msg").value.length > 0) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ to: num, msg: document.getElementById("msg").value })
            };
            this.setState({ isLoading: true });
            response = await fetch('api/comms/message', requestOptions)
            data = await response.json();
            // console.log(data);
            if (data.value.status === "OK") {
                var d = new Date();
                if (d.getMinutes().toString().length !== 2) {
                    mins = '0' + d.getMinutes();
                } else {
                    mins = d.getMinutes();
                }
                var timestamp = d.getHours() + ':' + mins + ' - ' + d.getDate() + '/' + parseInt(d.getMonth() + 1) + '/' + d.getFullYear();
                this.setState({ isLoading: false, success: true, timestamp: timestamp })
            }

        } else {
            alert("Message body cannot be blank");
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} onClosed={() => this.resetFields()} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-lt-orn">
                    <Row>
                        <Col xs="2" className="align-l">
                            <SendMsgIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            SEND SMS
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-dk-orn" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-dk-orn">
                    {this.state.isLoading ?
                        <div className="custom-modal-content">
                            <img src={loadingIcon} alt="Loading...." width="100px" height="100px" />
                        </div>
                            :
                        this.state.success ?
                            <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="msg-modal-content msg-result">
                                <p>Your message was sent successfully at: </p>
                                <br/>
                                <h5>{this.state.timestamp}</h5>
                                <br />
                                <p>To: </p>
                                {this.state.predefinedTo ?
                                    <h5>{this.state.displayName}</h5> :
                                    <h5>{this.state.numInput}</h5>}
                                <br />
                                <br />
                                <h5>Great Job!</h5>
                            </Col>
                                :
                            <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="msg-modal-content">
                                <FormGroup row noGutters={true}>
                                    <Label for="to" xs={2} xl={1}>
                                        To:
                                    </Label>
                                    {this.state.predefinedTo ?
                                        <Col xs={10} xl={11}>
                                            <Input type="text" id="to" value={this.state.displayName} disabled />
                                        </Col> :
                                        <Col xs={10} xl={11}>
                                            <Input onChange={this.handleNumChange} type="text" id="to" />
                                        </Col>}
                                </FormGroup>
                                <Input type="textarea" id="msg" />
                                <Button onClick={() => this.sendMsg()} className="sps-lt-orn">
                                    SEND
                                </Button>
                            </Col>
                        }
                    <Row noGutters={true}>
                        <Col xs="6" xl={2}>
                        </Col>
                        <Col xs="6" xl={{ size: 2, offset: 8 }} className="align-r">
                            <Button onClick={() => this.toggleModal()} className="msg-modal-btn">
                                CLOSE
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}