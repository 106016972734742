import { authService } from './auth.service';
import { handleResponse } from './response.service';
import { apiUrl } from './config';

export const apiService = {
    getData,
    postData
}

async function getData (path) {

    const requestOptions = { 
        method: 'GET', 
        headers: await authService.authHeader() 
    };

    return new Promise(async function (resolve, reject) {
        await fetch(apiUrl + path , requestOptions)
        .then(handleResponse)
        .then(data => { 
            return resolve(data);
        })
        .catch(error => {
            return reject(error);
        })
    });
}

async function postData(path, bodyData) {

    const requestOptions = { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json',  ...await authService.authHeader() },
        body: JSON.stringify(bodyData) 
    };

    return new Promise(async function (resolve, reject) {
        fetch(apiUrl + path , requestOptions)
        .then(handleResponse)
        .then(data => { 
            resolve(data);
        })
        .catch(error => {
            reject(error);
        })
    });
}