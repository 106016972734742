import React from 'react';
import { Row, Col } from 'reactstrap';

import { ReactComponent as GreenExpArrowOff } from '../../icons/Green-OffState-RecentReading.svg';
import { ReactComponent as GreenExpArrowOn } from '../../icons/Green-Rollover-RecentReading.svg';
import { ReactComponent as AmberExpArrowOff } from '../../icons/Amber-OffState-RecentReading.svg';
import { ReactComponent as AmberExpArrowOn } from '../../icons/Amber-Rollover-RecentReading.svg';
import { ReactComponent as RedExpArrowOff } from '../../icons/Red-OffState-RecentReading.svg';
import { ReactComponent as RedExpArrowOn } from '../../icons/Red-Rollover-RecentReading.svg';
import { ReactComponent as WhiteExpArrowOff } from '../../icons/OffState-WhiteExpandButton.svg';
import { ReactComponent as WhiteExpArrowOn } from '../../icons/Rollover-WhiteExpandButton.svg';
import loadingIcon from '../../images/loading.gif';

import ReadingsModal from './ReadingsModal';
import { apiService } from '../../services/api.service';
import { authService } from '../../services/auth.service';
import { processService } from '../../services/processing.service';
import { datetimeformatterService } from '../../services/datetimeformatter.service';


export default class RecentReadings extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            redisHover: false,
            amberisHover: false,
            greenisHover: false,
            whiteisHover: false,
            isLoading: true,
            readings: null
        }

    };

    openModal(filter) {
        this.refs.readingsModal.toggleModal(filter);
    }

    toggleHover(elem) {
        this.setState({ [elem + 'isHover']: !this.state[elem + 'isHover'] })
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        //Get all sensors for a site
        apiService.getData("/sensor?siteid=" + authService.currentSite)
        .then(sensors => {
            let sensorIds = []
            sensors.forEach(sensor => {
                sensorIds.push(sensor.originId);
            })
            return sensorIds;
        })
        .then(flatSensors => {
            //Using all the sensor ids call their readings
            apiService.postData("/sensor/recent", flatSensors)
            .then(readings => {
                //Process and sort readings
                let processedReadings = processService.ProcessReading(readings);
                let sortedReadings = processedReadings.sort((a, b) => {
                    var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                    return dateB - dateA;
                }); 
                // console.log(sortedReadings);
                this.setState({ readings: sortedReadings, isLoading: false })
            })
        })
    }

    render() {
        return (
            <Row className="rr-card-body" noGutters={true}>
                {this.state.isLoading ?
                    <Col xs={10}>
                        <img src={loadingIcon} className="rr-loading" alt="Loading...." width="100px" height="100px" />
                    </Col>
                    :
                    <Col xs={10} className="rr-readings">
                        <ReadingsModal ref="readingsModal" readings={this.state.readings} />
                        {this.state.readings.map((reading, index) => {
                            var colourClass;
                            if (reading.alertLevel === 0) {
                                colourClass = "green";
                            } else if (reading.alertLevel === 1) {
                                colourClass = "orange";
                            } else {
                                colourClass = "red";
                            }
                            return (
                                <Row key={index} className="rr-reading" noGutters={true}>
                                    <Col xs={2}>
                                        <div className={`rr-reading-colour rr-icon-bg-${colourClass}`}>
                                        </div>
                                    </Col>
                                    <Col className="rr-reading-text">
                                        {datetimeformatterService.formatDate(reading.readingTime)} <br />
                                        <strong>{reading.type}</strong>
                                    </Col>
                                    <Col xs={3}>
                                        {datetimeformatterService.formatTimeNoSeconds(reading.readingTime)}<br />
                                        <strong>{reading.value}{reading.unit}</strong>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                }
                {this.state.isLoading ?
                    <Col xs={2}></Col>
                    :
                    <Col xs={2} className="rr-icon-col">
                        <div className="rr-icon" onClick={() => this.openModal("red")} onMouseEnter={() => this.toggleHover("red")} onMouseLeave={() => this.toggleHover("red")}>
                            {this.state.redisHover ?
                                <RedExpArrowOn width={35} height={35} /> :
                                <RedExpArrowOff width={35} height={35} />}
                        </div>
                        <div className="rr-icon" onClick={() => this.openModal("amber")} onMouseEnter={() => this.toggleHover("amber")} onMouseLeave={() => this.toggleHover("amber")}>
                            {this.state.amberisHover ?
                                <AmberExpArrowOn width={35} height={35} /> :
                                <AmberExpArrowOff width={35} height={35} />}
                        </div>
                        <div className="rr-icon" onClick={() => this.openModal("green")} onMouseEnter={() => this.toggleHover("green")} onMouseLeave={() => this.toggleHover("green")}>
                            {this.state.greenisHover ?
                                <GreenExpArrowOn width={35} height={35} /> :
                                <GreenExpArrowOff width={35} height={35} />}
                        </div>
                        <div className="rr-icon-bg-grey" onClick={() => this.openModal("grey")} onMouseEnter={() => this.toggleHover("white")} onMouseLeave={() => this.toggleHover("white")}>
                            {this.state.whiteisHover ?
                                <WhiteExpArrowOn width={28} height={28} /> :
                                <WhiteExpArrowOff width={28} height={28} />}
                        </div>
                    </Col>
                }
            </Row>
        );
    }
}