import React, { Component } from 'react';
import { Collapse, Container, Nav, NavItem, NavLink, Col } from 'reactstrap';

import { List } from 'react-bootstrap-icons';

import { ReactComponent as NotifIconOff } from '../icons/OffState-Notifications.svg';
import { ReactComponent as NotifIconOn } from '../icons/Rollover-Notifications.svg';
import { ReactComponent as DashIconOff } from '../icons/OffState-Dashboard.svg';
import { ReactComponent as DashIconOn } from '../icons/Rollover-Dashboard.svg';
import { ReactComponent as SearchIconOff } from '../icons/OffState-Search.svg';
import { ReactComponent as SearchIconOn } from '../icons/Rollover-Search.svg';
import { ReactComponent as MessageIconOff } from '../icons/OffState-Messages.svg';
import { ReactComponent as MessageIconOn } from '../icons/Rollover-Messages.svg';
import { ReactComponent as UserIconOff } from '../icons/OffState-User.svg';
import { ReactComponent as UserIconOn } from '../icons/Rollover-User.svg';


import './NavMenu.scss';
import logo from "../images/Prod_by_METL.png";

export class NavMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collapsed: true,
            logo: <img src={logo} alt="Logo" />,
            notificationisHover: false,
            dashboardisHover: false,
            searchisHover: false,
            messageisHover: false,
            userisHover: false
        };
    }

    componentDidMount() {
        //Collapse the side nav if in portrait and less than 600px wide
        if (window.innerHeight > window.innerWidth && window.innerWidth <= 600) {
            this.setState({ collapsed: false, logo: <List size={40} onClick={() => this.toggleNavbar()} />  });
        }

        window.addEventListener("resize", function () {
            if (window.innerHeight > window.innerWidth && window.innerWidth <= 600) {
                this.setState({ collapsed: false, logo: <List size={40} onClick={() => this.toggleNavbar()} /> });
            }
            else {
                this.setState({ collapsed: true, logo: <img src={logo} alt="Logo" />});
            }
        }.bind(this), false);
    }
     
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleHover(elem) {
        this.setState({ [elem + 'isHover']: !this.state[elem + 'isHover']})
    }

    render() {

        return (
            <Container className="reset-mp" fluid={true}>
                <Collapse isOpen={this.state.collapsed}>
                    <Nav className="vert-nav" vertical>
                        <NavItem>
                            <NavLink href="#">
                                <div onMouseEnter={() => this.toggleHover("notification")} onMouseLeave={() => this.toggleHover("notification")}>
                                    <span className="health-widget-indicator critical badge badge-pill badge-danger">1</span>
                                    <span className="health-widget-indicator warning badge badge-pill badge-warning">3</span>
                                    <span className="health-widget-indicator normal badge badge-pill badge-success">23</span>
                                    {this.state.notificationisHover ?
                                        <NotifIconOn /> :
                                        <NotifIconOff />}
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">
                                <div onMouseEnter={() => this.toggleHover("dashboard")} onMouseLeave={() => this.toggleHover("dashboard")}>
                                    {this.state.dashboardisHover ?
                                        <DashIconOn /> :
                                        <DashIconOff />}
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">
                                <div onMouseEnter={() => this.toggleHover("search")} onMouseLeave={() => this.toggleHover("search")}>
                                    {this.state.searchisHover ?
                                        <SearchIconOn /> :
                                        <SearchIconOff />}
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">
                                <div onMouseEnter={() => this.toggleHover("message")} onMouseLeave={() => this.toggleHover("message")}>
                                    {this.state.messageisHover ?
                                        <MessageIconOn /> :
                                        <MessageIconOff />}
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">
                                <div onMouseEnter={() => this.toggleHover("user")} onMouseLeave={() => this.toggleHover("user")} >
                                    {this.state.userisHover ?
                                        <UserIconOn /> :
                                        <UserIconOff />} 
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
                <div className="nav-logo">
                    {this.state.logo}
                </div>
                <Nav className="hori-nav">
                    <Col xs={12}>
                        <h3>SPS ENGINEERS PORTAL</h3>
                        <p>CURRENTLY LOCATED AT: <strong>Smart Plant Systems, The Coach House, Yew Trees, Crowell Hill, Chinnor, OX39 4BT</strong></p>
                    </Col>
                </Nav>
            </Container>
        );
    }
}
