import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';

import { ReactComponent as DropArrowDown } from '../../icons/White-DropdownDown.svg';
import { ReactComponent as DropArrowUp } from '../../icons/White-DropdownUp.svg';

export default class DetailTile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
        }
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {

        var collapseIcon = this.state.isOpen ? <DropArrowUp width={30} height={30} /> : <DropArrowDown width={30} height={30} />

        return (
            <Card className="detail-tile">
                <CardHeader onClick={() => this.toggle()} v className={`detail-tile-${this.props.colour} detail-tile-header`}>
                    <Row noGutters={true}>
                        <Col className="detail-tile-icon">
                            {this.props.icon}
                        </Col>
                        <Col xs="8" md="8" lg="8" className="detail-tile-title">
                            {this.props.title}
                        </Col>
                        <Col className="detail-tile-collapse-icon">
                            {collapseIcon}
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody className="detail-tile-body">
                        {this.props.children}
                    </CardBody>
                </Collapse>
            </Card>
        );
    }
}

DetailTile.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.object,
    children: PropTypes.object
}
