export async function handleResponse(response) {
    return await response.text().then(async text => {
        const data = text && await JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}