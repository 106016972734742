import React from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';

import { apiService } from '../../services/api.service';
import { ReactComponent as FactOfTheDayIcon } from '../../icons/FactOfTheDay.svg';
import { ReactComponent as CloseIcon } from '../../icons/White-Close.svg';
import loadingIcon from '../../images/loading.gif';

var _isVisable = false;

export default class FactModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isLoading: false,
            fact: ''
        }
    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    getFact = () => {
        _isVisable = true;
        this.setState({ isLoading: true });
        //Get a fact from the database then add it to state
        apiService.getData("/fact").then(fact => {
            this.setState({ isLoading: false, fact: fact.factText });
            document.getElementById("next").disabled = true;

            this.speakFact(fact.factText);
        })
        .catch(e => {
            this.setState({ error: true, isLoading: false })
        })
    }

    speakFact(fact) {
        //Check for speech library
        if ('speechSynthesis' in window) {
            //Create speech object and grab a voice
            var msg = new SpeechSynthesisUtterance();
            var voices = window.speechSynthesis.getVoices();
            msg.voice = voices[0]; 

            //Give it the fact to read and a language
            msg.text = fact;
            msg.lang = "en-GB";

            //Speak the fact
            window.speechSynthesis.speak(msg);
            
            msg.onend = function () {
                // console.log(_isVisable);
                if (_isVisable) {
                    document.getElementById("next").disabled = false;
                }
            }
        }
    }

    handleClose() {
        //Close any fact still being spoken
        window.speechSynthesis.cancel();
        _isVisable = false;
    }

    render() {
        return (
            <Modal onOpened={() => this.getFact()} onClosed={() => this.handleClose()} isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="custom-modal">
                <div className="custom-modal-header sps-dk-gry">
                    <Row>
                        <Col xs="2" className="align-l">
                            <FactOfTheDayIcon width={30} height={30} className="custom-modal-icon" />
                        </Col>
                        <Col xs="8">
                            FACT OF THE DAY
                        </Col>
                        <Col xs="2" className="align-r">
                            <CloseIcon onClick={() => this.toggleModal()} width={35} height={35} className="custom-modal-close sps-lt-gry" />
                        </Col>
                    </Row>
                </div>
                <ModalBody className="sps-lt-gry">
                    <div className="custom-modal-content">
                        {this.state.isLoading ?
                            <img src={loadingIcon} alt="Loading...." width="100px" height="100px" />
                            :
                            this.state.error ? 
                                <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="fact-modal-content">
                                    Error loading data
                                </Col>
                            :
                                <Col xs={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} className="fact-modal-content">
                                    <h4>{this.state.fact}</h4>
                                    <Row noGutters={true}>
                                        <Col xs={6} xl={3}>
                                            <Button onClick={() => this.toggleModal()} className="sps-dk-gry">
                                                BACK
                                            </Button>
                                        </Col>
                                        <Col xs={{ size: 6 }} xl={{ size: 3, offset: 6 }}>
                                            <Button id="next" onClick={() => this.getFact()} className="sps-dk-gry">
                                                NEXT
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                        }
                    </div>
                    <Row noGutters={true}>
                        <Col xs="6" xl={2}>
                        </Col>
                        <Col xs="6" xl={{ size: 2, offset: 8 }} className="align-r">
                            <Button onClick={() => this.toggleModal()} className="fact-modal-btn">
                                CLOSE
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}